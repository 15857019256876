import "../styles/landingPage.scss";
import Arrow from "../assets/svgs/icon-arrow.svg";
import FeatureImg1 from "../assets/pngs/feature-img-1.png";
import FeatureImg2 from "../assets/pngs/feature-img-2.png";
import FeatureImg3 from "../assets/pngs/feature-img-3.png";
import Twitter from "../assets/svgs/icon-twitter.svg";
import Zealy from "../assets/pngs/img-zealy.png";
import Discord from "../assets/svgs/icon-discord.svg";
import Telegram from "../assets/svgs/icon-telegram.svg";
import Header from "../components/Header";
import Backers from "../assets/pngs/img-backers.png";
import useScrollFadeIn from "../common/useScrollFadeIn";
import { useEffect, useState } from "react";
import LogoLeft from "../assets/pngs/logo-lg-left.png";
import LogoRight from "../assets/pngs/logo-lg-right.png";
import HashLock from "../assets/pngs/img-hashlock-logo.png";
import { ROUTES } from "../routes/ROUTES";

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={"landing-page"}>
      <Header />
      <div className={"main-page"}>
        <img
          {...useScrollFadeIn("down", 1, 0)}
          className={"logo-lg-left"}
          src={LogoLeft}
          alt={""}
        />
        <img
          {...useScrollFadeIn("up", 1, 0)}
          className={"logo-lg-right"}
          src={LogoRight}
          alt={""}
        />
        <div className={"catch-phrase"}>
          The ultimate
          <br />
          perpetual aggregator
          <br />
          for pro traders
        </div>
        <div className={"description"}>
          Experience one-stop cross margin trading
          <br /> with multiple perp DEX positions
          <br />
          Built on Cross Chain
        </div>
        <div
          className={"main-button"}
          onClick={() => window.open("https://app.dvx.trading", "_blank")}
        >
          <> Launch App</>
          <img className={"icon-arrow"} src={Arrow} alt={"arrow"} />
        </div>
      </div>

      <div {...useScrollFadeIn("up", 1, 0)} className={"audit-page"}>
        <div className={"landing-title"}> SECURED BY</div>
        <img
          onClick={() => {
            window.open("https://hashlock.com", "_blank");
          }}
          className={"audit-img"}
          src={HashLock}
          alt={""}
        />
      </div>
      <div className={"features-page"}>
        <div className={"landing-title"}>Features</div>
        <div {...useScrollFadeIn("right", 1, 0)} className={"features-box-top"}>
          <div className={"feature-box-lg first"}>
            <div className={"title-sub"}>seamless</div>
            <div className={"title-main"}>
              trading <br /> experience
            </div>
            <div className={"feature-description lg"}>
              Proceed the transactions within DVX without additional signatures,
              using Hybrid Trading Account.
            </div>
          </div>
          <div className={"feature-box-lg second"}>
            <div className={"title-sub"}>enjoy</div>
            <div className={"title-main"}>cross margin</div>
            <div className={"feature-description lg"}>
              No need to manage margins on each Perp DEXs. Manage your margins
              in a single DVX account.
            </div>
          </div>
        </div>
        <div
          {...useScrollFadeIn("left", 1, 0)}
          className={"features-box-bottom"}
        >
          <div className={"feature-box-sm"}>
            <div className={"feature-box-title"}>
              <div className={"title-sub"}>CROSS PLATFORM</div>
              <div className={"title-main"}>
                INTEGRATED <br /> POSITION
              </div>
              <div className={"feature-description"}>
                Access your positions across multiple exchanges at once.
              </div>
            </div>
            <img
              className={"feature-img"}
              src={FeatureImg3}
              alt={"feature-img"}
            />
          </div>
          <div className={"feature-box-sm"}>
            <div className={"feature-box-title"}>
              <div className={"title-sub"}>lowest</div>
              <div className={"title-main"}>prices</div>
              <div className={"feature-description"}>
                DVX combines fragmented liquidity from multiple Perp DEXs on
                Cross Chain for the best price.
              </div>
            </div>
            <img
              className={"feature-img"}
              src={FeatureImg1}
              alt={"feature-img"}
            />
          </div>
          <div className={"feature-box-sm"}>
            <div className={"feature-box-title"}>
              <div className={"title-sub"}>liquidity</div>
              <div className={"title-main"}>aggregation</div>
              <div className={"feature-description"}>
                Aggregated liquidity from multiple leading Perp DEXs makes you
                open maximum position across Perp DEXs combined.
              </div>
            </div>
            <img
              className={"feature-img"}
              src={FeatureImg2}
              alt={"feature-img"}
            />
          </div>
        </div>
      </div>

      <div className={"backers-page"}>
        <img className={"backers-img"} src={Backers} alt={""} />
        <div className={"landing-title"}> BACKERS</div>
        <div className={"backers-description"}>To Be Announced</div>
      </div>

      <div {...useScrollFadeIn("up", 1, 0)} className={"community-page"}>
        <div className={"landing-title"}>Community</div>
        <div className={"community-box"}>
          <div
            className={"sns-container"}
            onClick={() => {
              window.open("https://discord.gg/dvxtrading", "_blank");
            }}
          >
            <img className={"sns"} src={Discord} alt={""} />
          </div>
          <div
            className={"sns-container"}
            onClick={() => {
              window.open("https://twitter.com/dvx_official", "_blank");
            }}
          >
            <img className={"sns"} src={Twitter} alt={""} />
          </div>
          {/* <div
            className={"sns-container"}
            onClick={() => {
              window.open("https://t.me/dvxtrading", "_blank");
            }}
          >
            <img className={"sns"} src={Telegram} alt={""} />
          </div> */}
          {/* <div
            className={"sns-container"}
            onClick={() => {
              window.open("https://zealy.io/cw/dvx/questboard", "_blank");
            }}
          >
            <img className={"sns"} src={Zealy} alt={""} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
